import React from 'react'
import { Link } from 'gatsby'
import { 
    MDBNavbar,
    MDBNavItem,
    MDBCollapse,
    MDBNavbarNav, 
    MDBNavbarBrand, 
    MDBNavbarToggler
} from 'mdbreact';
import brand from '../images/Branding/kelsey-logo-beta-web.svg'

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isScrollAtTop:true,
            isOpen:false
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    componentDidMount() {
        if(this.props.fancy){
            window.addEventListener('scroll',this.handleScroll);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        if (document.body.scrollTop > 200 || 
            document.documentElement.scrollTop > 200){
                this.setState({isScrollAtTop:false})
            }
        else{
            this.setState({isScrollAtTop:true})
        }
    }
    toggleCollapse() {
        this.setState({isOpen: !this.state.isOpen})
    }
    render(){
        return(
            <>
            <MDBNavbar expand='md' light className={this.state.isScrollAtTop && this.props.fancy? 'isAtTop' : 'isNotAtTop'}>
                <MDBNavbarBrand>
                    <Link to='/'>
                        <div id='logo' className='logo'>
                            <img src={brand} alt='header-logo'/>
                        </div>
                    </Link>
                </MDBNavbarBrand>
                <MDBNavbarToggler aria-controls='basic-navbar-nav' onClick={this.toggleCollapse} left/>
                <MDBCollapse isOpen={this.state.isOpen} navbar id='basic-navbar-nav'>
                    <MDBNavbarNav right>
                        <MDBNavItem>
                            <Link to='/#about'>About</Link>
                        </MDBNavItem>
                        <MDBNavItem >
                            <Link to='/#portfolio'>Portfolio</Link>
                        </MDBNavItem>
                        <MDBNavItem>
                            <Link to='/gallery'>Gallery</Link>
                        </MDBNavItem>
                        {/* <MDBNavItem>
                            <Link to='#contact'>Contact</Link>
                        </MDBNavItem> */}
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
            </>
        )
    }
}

export default Header