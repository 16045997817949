import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types';
import favicon from '../images/Branding/favicon.ico';

const Layout = (props) => {
    let title = props.title || 'Kelsey Makida';
    let desc = props.description || undefined;
    let noRoboIndex= props.noRoboIndex || false;

    if (typeof window !== 'undefined'){
        require('smooth-scroll')(['a[href="/#about"]','a[href="/#portfolio"]'],{
            offset: () =>{
                const navbar = document.getElementsByClassName('navbar')[0]
                if(navbar.clientWidth > 767){
                    return document.getElementsByClassName('navbar')[0].offsetHeight;
                }
                else return 0;
            }
        })
    }
    return(
        <>
        <Helmet>
            <title>{title}</title>
            <link rel='icon' href={favicon}/>
            {desc && <meta name='description' content={desc}/>}
            {noRoboIndex && <meta name='robots' content='noindex'/>}
        </Helmet>
        <Header fancy={props.fancyHeader}/>
        {props.children}
        <Footer/>
        </>
    )
}

Layout.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    noRoboIndex: PropTypes.bool
}

export default Layout