import React from 'react';
import instaLogo from '../images/Social/instagram.svg';
const Instagram = () => {
    return(
        <div id='instagram'>
            <a href='https://www.instagram.com/kmakida/' target='_blank' rel="noreferrer">
                <img src={instaLogo} alt = 'instagram-logo' style={{width:'100%'}}/>
            </a>
        </div>
    )
}

export default Instagram;