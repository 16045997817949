import React from 'react';
import {Link} from 'gatsby';
import Instagram from '../components/Social';
import {MDBFooter, MDBContainer,MDBRow, MDBCol} from 'mdbreact';
const Footer = () =>{
    return(
        <MDBFooter>
            <MDBContainer>
                <MDBRow between>
                    <MDBCol>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/#portfolio'>Portfolio</Link></li>
                            <li><Link to='/gallery'>Gallery</Link></li>
                            <li><Link to='/#about'>About</Link></li>
                        </ul>
                    </MDBCol>
                    <MDBCol middle>
                        <Instagram/>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <div className="footer-copyright text-center py-3">
                <MDBContainer fluid>
                &copy; {new Date().getFullYear()} Copyright: <a href="https://www.kelseymakida.com"> KelseyMakida.com </a>
                </MDBContainer>
            </div>
        </MDBFooter>
    );
}

export default Footer;